import { AppBar, Box, CssBaseline, Link, Toolbar, Typography } from "@mui/material";
import { Outlet } from "react-router-dom";
import { Link as ReactRouterLink } from "react-router-dom";

export function Layout() {
    const themeColor = "2196F3";
    const bannerLogoUrl = "https://ipm-rattenbeheersing.ams3.digitaloceanspaces.com/assets/icon_wide.png";

    return (
        <Box sx={{ display: "flex" }}>
            <CssBaseline />

            <Box display="flex" flexDirection="column" minHeight="100vh" width="100%">
                <Box
                    width="100%"
                    height="7em"
                    style={{
                        backgroundImage: `url(${bannerLogoUrl})`,
                        backgroundPosition: "left",
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat"
                    }}
                />
                <AppBar
                    position="relative"
                    sx={{
                        zIndex: theme => theme.zIndex.drawer + 1,
                        backgroundColor: `#${themeColor}`,
                        flex: 0
                    }}
                >
                    <Toolbar>
                        <Typography variant="h6" noWrap component="div">
                            <Link
                                component={ReactRouterLink}
                                to="/"
                                color="inherit"
                                underline="none"
                            >
                                Privacybeleid IPM Knaagdierbeheersing
                            </Link>
                        </Typography>
                    </Toolbar>
                </AppBar>

                <Box style={{ margin: "1em" }}>
                    <Outlet />
                </Box>
            </Box>
        </Box>
    )
}