import MuiMarkdown from 'mui-markdown'

const markdown = `
### Privacybeleid IPM Knaagdierbeheersing

##### * Persoonsgegevens die wij verwerken
IPM Knaagdierbeheersing verwerkt uw persoonsgegevens doordat u gebruik maakt van onze diensten en/of omdat u deze zelf aan ons verstrekt.


##### * Hieronder vindt u een overzicht van de persoonsgegevens die wij verwerken:
- Voor- en achternaam (verplicht)
- Adresgegevens	(verplicht)
- Telefoonnummer (verplicht)
- E-mailadres (verplicht)
- Foto's van uw locatie (binnen of buiten) (met toestemming via handtekening)
- Handtekening
- Alle andere informatie op basis van wat gevraagd wordt binnen een werkbon uitgevoerd door uw bestrijder


##### * Hoe lang bewaren we persoonsgegevens
IPM Knaagdierbeheersing heeft geen actieve retentie periode van uw data, dit wordt beheerd door de individuele bedrijven die uw data invoeren in het systeem.
Uw data kan verwijderd worden na een bestrijdings proces voltooid te hebben, het bedrijf die uw bestrijding uitgevoerd heeft is hier verantwoordlijk voor om dit te doen.
Klanten van deze bedrijven kunnen op ieder moment inloggen in het systeem om zelf data te kunnen verwijderen.
Indient u geen e-mail krijgt voor een uitnodiging in IPM Knaagdierbeheersing van uw bestrijder kunt u altijd zelf inloggen bij het logboek uw bestrijder
met het e-mail adres dat de bestrijder van u beschikt om uw data te kunnen verwijderen.


##### * Hoe verwijderen wij uw gegevens
IPM Knaagdierbeheersing doet uw inlog accounts volledig verwijderen, dit is alleen referentie naar uw e-mail adres en wachtwoord.
Uw klantgegevens die het bedrijf verzameld om werkbonnen te kunnen maken wordt alleen geanonimiseerd zodat het bedrijf nog wel extra gegevens terug kan vinden, maar deze niet meer kan koppelen aan uw naam.
Alle gegevens die in werkbonnen staat worden niet geanonimiseerd omdat bedrijven IPM audits kunnen krijgen, deze moeten vervolgens historische gegevens van alle werkbonnen verplicht laten zien, u kunt altijd
uw bestrijden een mail sturen of uw werkbonnen verwijderd kunnen worden, IPM Knaagdierbeheersing regelt dit niet.


##### * Delen van persoonsgegevens met derden
IPM Knaagdierbeheersing verstrekt data niet aan derde partijën, uw gegevens kunnen alleen gezien worden door u en uw bestrijder.
Het is mogelijk dat een IPM auditor ook uw werkbonnen te zien krijgt maar deze worden niet gedeeld met de auditor.


##### * Cookies, of vergelijkbare technieken, die wij gebruiken
IPM Knaagdierbeheersing gebruikt alleen technische en functionele cookies.


##### * Gegevens inzien, aanpassen of verwijderen
U heeft het recht om uw persoonsgegevens in te zien, te corrigeren of te verwijderen. Dit kunt u zelf doen via de persoonlijke instellingen van uw account. Daarnaast heeft u het recht om uw eventuele toestemming voor de gegevensverwerking in te trekken of bezwaar te maken tegen de verwerking van uw persoonsgegevens door ons bedrijf en heeft u het recht op gegevensoverdraagbaarheid. Dat betekent dat u bij ons een verzoek kunt indienen om de persoonsgegevens die wij van u beschikken in een computerbestand naar u of een ander, door u genoemde organisatie, te sturen.
Wilt u gebruik maken van uw recht op bezwaar en/of recht op gegevensoverdraagbaarheid of heeft u andere vragen/opmerkingen over de gegevensverwerking, stuur dan een gespecificeerd verzoek naar info@ipmknaagdierbeheersing.nl.
Om er zeker van te zijn dat het verzoek tot inzage door u is gedaan, vragen wij u een kopie van uw identiteitsbewijs bij het verzoek mee te sturen. Maak in deze kopie uw pasfoto, MRZ (machine readable zone, de strook met nummers onderaan het paspoort), paspoortnummer en Burgerservicenummer (BSN) zwart. Dit ter bescherming van uw privacy. IPM Knaagdierbeheersing zal zo snel mogelijk, maar in ieder geval binnen vier weken, op uw verzoek reageren.
IPM Knaagdierbeheersing wil u er tevens op wijzen dat u de mogelijkheid hebt om een klacht in te dienen bij de nationale toezichthouder, de Autoriteit Persoonsgegevens. Dat kan via de volgende link: https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons


##### * Hoe wij persoonsgegevens beveiligen
IPM Knaagdierbeheersing neemt de bescherming van uw gegevens serieus en neemt passende maatregelen om misbruik, verlies, onbevoegde toegang, ongewenste openbaarmaking en ongeoorloofde wijziging tegen te gaan. Als u de indruk heeft dat uw gegevens niet goed beveiligd zijn of er zijn aanwijzingen van misbruik, neem dan contact op via info@ipmknaagdierbeheersing.nl.
`

export function PrivacyPage() {
    return (
        <MuiMarkdown children={markdown} />
    )
}